// extracted by mini-css-extract-plugin
export var takeOver__close = "takeOver100-module--takeOver__close---2C36";
export var takeOver__columnCta = "takeOver100-module--takeOver__columnCta--HlDjG";
export var takeOver__columnJerseyCta = "takeOver100-module--takeOver__columnJerseyCta--Aik-6";
export var takeOver__columnTextCta = "takeOver100-module--takeOver__columnTextCta--hxHAD";
export var takeOver__container = "takeOver100-module--takeOver__container--kxG5-";
export var takeOver__content = "takeOver100-module--takeOver__content--2bFo5";
export var takeOver__left = "takeOver100-module--takeOver__left--2YHWC";
export var takeOver__offsetContainer = "takeOver100-module--takeOver__offsetContainer--DVov-";
export var takeOver__offsetImage = "takeOver100-module--takeOver__offsetImage--+FN6x";
export var takeOver__right = "takeOver100-module--takeOver__right--YDWbO";
export var takeOver__rightContent = "takeOver100-module--takeOver__rightContent--PAnt0";
export var takeOver__showDesktop = "takeOver100-module--takeOver__showDesktop--eLFyr";
export var takeOver__wrapper = "takeOver100-module--takeOver__wrapper--jQzY8";
export var takeoverNoScroll = "takeOver100-module--takeoverNoScroll--p+Is5";