import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import { StaticImage } from 'gatsby-plugin-image';

import * as style from 'styles/components/utils/takeOver100.module.scss';

import leftimage from '../../images/takeover/100_left.jpg';
import offsetimage from '../../images/takeover/offset.png';

const TakeOver = ({ takeoverConfig }) => {

  const link = takeoverConfig.youtubeVideoId ? takeoverConfig.youtubeVideoId : null;

  const [isTakeOverActive, toggleTakeOver] = useState(true);

  function fakeNavigation() {
    toggleTakeOver(false);
    document.getElementById("seasonalTakeover").remove();
  }

  return (
    <div id="seasonalTakeover" className={style.takeOver__wrapper}>
      {isTakeOverActive &&
        <Helmet>
            <body data-modal-open="true" />
        </Helmet>
      }
      <div className={style.takeOver__container}>
        <div className={style.takeOver__content}>
          <button type="button" className={style.takeOver__close} aria-label='Stäng' data-testid="close-button" onClick={() => fakeNavigation(false)}>
            <svg viewBox="0 0 36 36" data-testid="close-icon" fill="#000000" width="32" height="32"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"/></svg>
          </button>
          <div className={style.takeOver__left}>
            <img src={leftimage} alt="Framåt mot härliga tider" />
              <div className={style.takeOver__columnJerseyCta}>
              {link && (
                <a className={`${style.takeOver__columnCta} ${style.takeOver__showDesktop}`} target="_blank" rel="noreferrer" href={link}>Köp tröjan</a>
              )}
              </div>
          </div>
          <div className={style.takeOver__right}>
            <div className={style.takeOver__rightContent}>
              <h2>Allsvenskan 100 år</h2>
              <p>
                Allsvenskan firar 100 år och Hammarby IF var med från starten 1924. För att uppmärksamma 100 års-jubileet har Hammarby återskapat klubbens första allsvenska ställ – tigerrandiga tröjor med blå shorts. Upplev matchstället på plats den 26 augusti, när Hammarby tar emot GAIS på Nya Söderstadion i en jubileumsomgång av Allsvenskan. <a href="https://hammarbyherr.ebiljett.nu/Tickets/Choose/514?S=0" target="_blank" rel="noreferrer">Säkra biljett till matchen.</a>
              </p>
            </div>
            <div className={style.takeOver__offsetContainer}>
              <div className={style.takeOver__columnTextCta}>
                <a className={`${style.takeOver__columnCta} ${style.takeOver__showDesktop}`} href="https://www.hammarbyfotboll.se/nyheter/hammarbys-jubileumsstaell-foer-allsvenskan-100-ar">Läs mer om matchstället</a>
              </div>
              <div className={style.takeOver__offsetImage}>
                <img src={offsetimage} alt="Framåt mot härliga tider" />
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};
export default TakeOver;
