import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_USER_POOL_WEB_CLIENT_ID,

    oauth: {
        domain: process.env.GATSBY_COGNITO_OAUTH_DOMAIN,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: process.env.GATSBY_COGNITO_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.GATSBY_COGNITO_REDIRECT_SIGN_OUT,
        responseType: 'token',
    },
});

export const AuthContext = React.createContext({
    user: null,
    userName: '',
    isSignedIn: null,
    signIn: async () => {},
    signOut: async () => {},
});

export const useAuth = () => useContext(AuthContext);

export function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [userName, setUserName] = useState(null);
    const [isSignedIn, setIsSignedIn] = useState(null);

    useEffect(() => {
        let active = true;

        const check = async () => {
            try {
                await Auth.currentSession();
                const fetchedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
                if (active) {
                    // As the provider override the 'name' attribute on each login,
                    // we use a custom attribute that can be edited by the user.
                    setUserName(fetchedUser.attributes['custom:name'] || fetchedUser.attributes.name);
                    setUser(fetchedUser);
                    setIsSignedIn(true);
                }
            } catch (error) {
                if (active) {
                    setUser(null);
                    setUserName(null);
                    setIsSignedIn(false);
                }
            }
        };

        check();

        return () => { active = false; };
    }, [setUser, setIsSignedIn]);

    const signIn = useCallback(async () => {
        setUser(await Auth.federatedSignIn({ provider: 'Fotbollskonto' }));
    }, [setUser]);

    const signOut = useCallback(async () => {
        await Auth.signOut();
        setUser(null);
    }, [setUser]);

    const updateUser = useCallback(async (attr, value) => {
        try {
            await Auth.updateUserAttributes(user, {
                [attr]: value,
            });

            const updatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
            setUser(updatedUser);

            if (attr === 'custom:name') {
                setUserName(updatedUser.attributes['custom:name'] || updatedUser.attributes.name);
            }
        } catch (error) {
            return error;
        }

        return null;
    }, [user]);

    const deleteUser = useCallback(async () => {
        try {
            await Auth.deleteUser();
            setUser(null);
        } catch (error) {
            return error;
        }

        return null;
    }, [setUser]);

    return { user, userName, isSignedIn, signIn, signOut, updateUser, deleteUser };
}

export const AuthProvider = ({ children }) => {
    const auth = useProvideAuth();

    // eslint-disable-next-line react/jsx-filename-extension
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
